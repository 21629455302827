.unsubscribe-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px - 55px); /* Account for header and footer height */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
}

/* Paragraph Styles */
.unsubscribe-container p {
    margin-bottom: 20px;
}

/* Form Styles */
.unsubscribe-container form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

/* Input Styles */
.unsubscribe-container input {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff; 
    font-size: 14px;
    font-family: Arial, sans-serif;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.unsubscribe-container input:focus {
    border-color: #0056b3;
}

/* Button Selector */
.unsubscribe-container .unsubscribe-button {
    width: 140px;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    text-align: center;
}

/* Scoped Hover State */
.unsubscribe-container .unsubscribe-button:hover {
    background-color: #003d82;
}

.response-message {
    margin-top: 10px;
    color: red;
}
