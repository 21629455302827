/* General styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px - 55px); /* Account for header and footer height */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
}

/* Content Styling */
.content {
    margin-top: 0;
}

h1 {
    font-size: 2.5rem;
    color: maroon;
    margin-bottom: 20px;
}

p {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

strong {
    font-size: 1.3rem;
    color: maroon;
}
