html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
}

.deactivate-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px - 55px); /* Account for header and footer height */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
}

p {
    margin-bottom: 20px; /* Consistent gap between text and input/button */
}

/* Align input and button horizontally */
form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

input {
    width: 100%;
    max-width: 250px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff; 
    font-size: 14px;
    font-family: Arial, sans-serif;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

input:focus {
    border-color: #0056b3;
}

.deactivate-container button {
    width: 200px;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    text-align: center;
}

button:hover {
    background-color: #003d82;
}

.response-message {
    margin-top: 10px;
    color: red;
}

/* Footer styles for Deactivate page */
.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
}