.faq-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    color: #660000;
}

.faq-container h1 {
    text-align: center;
    font-size: 2.5rem;
    color: #800000;
}

.faq-list {
    margin-top: 20px;
}

.faq-item {
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 10px;
}

.faq-question {
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    padding: 10px;
    background-color: #fff5f5;
    transition: background-color 0.3s ease;
}

.faq-question:hover {
    background-color: #f9a8c275;
}

.faq-answer {
    font-size: 1rem;
    padding: 10px;
    display: none;
    background-color: #fff5f5;
}

.faq-answer.open {
    display: block;
}
