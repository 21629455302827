.footer {
    background-color: #333;
    height: 55px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: #f0f0f0;
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
}

.footer-links {
    display: flex;
    gap: 20px;
}

.footer-link {
    text-decoration: underline;
    font-family: 'Arial', sans-serif;
    color: #f0f0f0;
    text-transform: lowercase;
    font-size: 0.9rem;
    cursor: pointer;
}

.footer-contact {
    margin: 0;
    font-family: 'Verdana', sans-serif;
    font-size: 0.9rem;
}

.footer-link:hover {
    color: #7b2b3b;
}

@media (max-width: 600px) {
    .footer-links {
        justify-content: space-between;
        width: 100%;
    }

    .footer-link {
        flex: 1;
        text-align: center;
    }

    .footer-contact {
        display: none;
    }
}

