html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .clear-likes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px - 55px); /* Account for header and footer height */
    box-sizing: border-box;
    text-align: center;
    padding: 20px;
  }
  
  p {
    margin-bottom: 20px; /* Consistent gap between text and input/button */
  }
  
  /* Align input and button horizontally */
  form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .clear-likes-button {
    width: 200px;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .clear-likes-button:hover {
    background-color: #003d82;
  }
  
  .response-message {
    margin-top: 10px;
    color: red;
  }
  
  .confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 2px solid #800000;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .popup-buttons {
    margin-top: 15px;
  }
  
  .popup-buttons .clear-likes-button {
    margin: 0 10px;
  }
  
  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: auto;
  }
  