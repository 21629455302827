/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
    background-color: #f0f0f0;
    overflow-x: hidden;
}

.login-page {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.login-background {
    background-image: url('/public/images/background_image.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: calc(100vh - 57px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    flex-shrink: 0;
}

.overlay {
    text-align: center;
    color: white;
}

.header-text {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-transform: none;
    color: #FFFFFF;
}

.login-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #800000;
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: none;
}

.login-button:hover {
    background-color: #660000;
}

.scroll-arrow {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 3.5rem;
    color: white;
    cursor: pointer;
    animation: bounce 2s infinite;
}

.second-screen {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    background-color: #fff5f5;
    min-height: calc(100vh - 10000px);
}

.left-side {
    width: 50%;
    text-align: left;
}

.right-side {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 20px;
}

.stats-heading {
    font-size: 3rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 30px;
    text-align: center;
    text-transform: none;
}

.stats-columns {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    width: 100%;
}

.left-column, .right-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.left-column .stat, .right-column .stat {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}

.left-column .stat .icon, .right-column .stat .icon {
    font-size: 2.5rem;
    color: #FFC0CB;
}

.left-column .stat h2, .right-column .stat h2 {
    margin-left: 15px;
    font-size: 2rem;
    font-weight: bold;
    color: #800000;
    text-transform: none;
}

.feature-title {
    font-size: 3.6rem;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
    color: #800000;
    margin-bottom: 15px;
    text-transform: none;
}

.feature-description {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 40px;
    text-transform: none;
}

.header {
    background-color: #FFF5E1;
}

.logo,
.nav-links {
    color: #800000;
}

@media (max-width: 1200px) {
    .second-screen {
        flex-direction: column;
        align-items: center;
    }

    .left-side, .right-side {
        width: 100%;
    }

    .stats-columns {
        flex-direction: column;
        gap: 20px;
    }

    .left-column, .right-column {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .header-text {
        font-size: 2rem;
    }

    .stats-heading {
        font-size: 2.5rem;
    }

    .feature-title {
        font-size: 2.5rem;
    }

    .feature-description {
        font-size: 1.2rem;
    }

    .stat h2 {
        font-size: 1.5rem;
    }

    .icon {
        font-size: 2rem;
    }

    .stats-columns {
        flex-direction: column;
        gap: 20px;
    }

    .left-column, .right-column {
        width: 100%;
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(-50%) translateY(0);
    }
    40% {
      transform: translateX(-50%) translateY(-15px);
    }
    60% {
      transform: translateX(-50%) translateY(-10px);
    }
}

html {
    scroll-behavior: smooth;
}

.stats-loading {
    font-size: 1.2rem;
    color: #800000;
    text-align: center;
    margin-top: 20px;
}

.stats-error {
    font-size: 1.2rem;
    color: #FF0000;
    text-align: center;
    margin-top: 0px;
}
