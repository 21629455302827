html, body {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex: 1;
}

.footer {
    background-color: #333;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    color: #f0f0f0;
    width: 100%;
    box-sizing: border-box;
    margin-top: auto;
}

.footer-links {
    display: flex;
    gap: 20px;
}

.footer-link {
    text-decoration: underline;
    font-family: 'Arial', sans-serif;
    color: #f0f0f0;
    text-transform: lowercase;
}

.footer-contact {
    margin: 0;
    font-family: 'Verdana', sans-serif;
}

.footer a:hover {
    color: #7b2b3b;
}
