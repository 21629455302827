.header-container {
    background-color: #f9a8c275;
    height: 60px;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    z-index: 2;
    position: relative;
    overflow: hidden;
}

/* Logo Styles */
.header-logo {
    font-size: 2rem;
    color: #660000;
    font-weight: bold;
    text-decoration: underline;
    font-style: italic;
}

.header-logo-link {
    text-decoration: none;
    color: #660000;
}

.header-logo-link:hover {
    color: #7b2b3b;
}

/* Navigation and Authentication Container */
.header-nav-auth {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 20px;
}

/* Navigation Links */
.header-nav-links {
    font-size: 1rem;
    color: #660000;
    font-style: italic;
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
}

.header-nav-links a {
    text-decoration: underline;
    color: #660000;
    font-style: italic;
    transition: color 0.3s ease;
}

.header-nav-links a:hover,
.header-nav-links a:active,
.header-nav-links a:focus {
    color: #7b2b3b;
}

/* Log In Link styled like other navigation links */
.header-login-link {
    text-decoration: underline;
    color: #660000;
    font-style: italic;
    cursor: pointer;
    transition: color 0.3s ease;
}

.header-login-link:hover,
.header-login-link:active,
.header-login-link:focus {
    color: #7b2b3b;
}

/* Log Out Button styled as a link */
.header-nav-links .header-logout-link {
    appearance: none;
    -webkit-appearance: none; /* For Safari */
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: #660000;
    text-decoration: underline;
    font-style: italic;
    font-size: 1rem;
    font-family: inherit;
    line-height: inherit;
    display: inline;
    transition: color 0.3s ease;
    width: auto;
    height: auto;
}

/* Hover and Focus States */
.header-nav-links .header-logout-link:hover,
.header-nav-links .header-logout-link:active,
.header-nav-links .header-logout-link:focus {
    color: #7b2b3b;
    outline: none;
}

.header-nav-links .header-logout-link:focus-visible {
    outline: 2px dashed #7b2b3b;
    outline-offset: 2px;
}