.confirmation-popup {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 90%; /* Wider on mobile */
    max-width: 600px;
    margin: 20px auto; /* Adds vertical margin to prevent cut-off */
    text-align: center;
    box-sizing: border-box;
}

h2 {
    font-size: 2rem;
    margin-bottom: 15px;
}

.popup-text {
    font-size: 1rem;
    margin: 10px 0;
    line-height: 1.5;
}

.popup-buttons {
    margin-top: 20px;
}

.like-button {
    font-size: 1rem;
    padding: 10px 15px;
    background-color: #7b2b3b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Hover effect for the button */
.like-button:hover {
    background-color: #5b1e2d;
}

/* Mobile-specific adjustments */
@media (max-width: 600px) {
    .confirmation-popup {
        padding: 15px; /* Reduce padding for more space on smaller screens */
        width: 90%; /* Maximize width on mobile */
    }

    h2 {
        font-size: 1.8rem; /* Smaller font for heading */
    }

    .popup-text {
        font-size: 0.9rem; /* Slightly smaller text for better fit */
        margin: 8px 0;
    }

    .popup-buttons {
        margin-top: 15px;
    }

    .like-button {
        font-size: 1rem;
        padding: 10px 15px;
        width: 100%; /* Full width on mobile */
    }
}
