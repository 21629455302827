.error-box {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 20px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    z-index: 1000;
    animation: fadeout 10s forwards;
  }
  
  @keyframes fadeout {
    0% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
  }
  