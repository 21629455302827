.about-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .about-level {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  /* Highest level: What is Maroon Match */
  .highest-level {
    display: flex;
    align-items: center;
  }
  
  /* Middle level: Okay, Why? and How? side by side */
  .middle-level {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  /* Lowest level: Ok, I'm Sold */
  .lowest-level {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Text and image container styles */
  .about-text {
    width: 48%;
  }
  
  .about-image {
    width: 50%;
    text-align: center;
  }
  
  .about-image img {
    max-width: 90%;
    height: auto;
    margin-top: 35px;
  }
  
  .caption {
    font-size: 1.1rem;
    color: gray;
    margin-top: 8px;
  }
  
  /* Centered content for the "i got you" button and text */
  .centered-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* CTA Button Style */
  .cta-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #800000;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: none;
    margin-top: 20px;
  }
  
  .cta-button:hover {
    background-color: #660000;
  }
  
  /* General heading and paragraph styles */
  h1 {
    color: #660000;
    font-size: 2.3rem;
    margin-bottom: 10px;
    text-transform: lowercase;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.4;
    text-transform: lowercase;
  }
  
  @media (max-width: 768px) {
    .highest-level, .lowest-level, .middle-level {
      flex-direction: column;
      align-items: center;
    }
  
    .about-text, .about-image {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .about-image img {
      max-width: 100%;
    }
  }
  