/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
    background-color: #f0f0f0;
}

/* LikeForm Container */
.like-form-container {
    width: 95%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 40px;
    background-color: #fff0f5; /* Pinkish box */
    border-radius: 20px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    /* This ensures columns and 'how does it work' stretch vertically,
       letting us push the link down near the bottom. */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    font-size: 1.2rem;
    height: 80vh;
}

/* "submit a like" title */
.form-title {
    font-size: 2.8rem;
    font-weight: bold;
    color: #800000;
    text-transform: lowercase;
    margin-bottom: 10px; /* Minimizing so input & button sit closer to heading */
}

/* The Like Form (input + button) */
.like-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* small gap between input & button */
    margin-bottom: 0; /* Removing large bottom margin so they’re raised up */
}

.like-input {
    padding: 10px;
    font-size: 18px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #ffffff;
    width: 40%;
}

.like-button {
    padding: 10px 20px;
    background-color: #800000;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.like-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.like-button:hover {
    background-color: #660000;
}

.message {
    font-size: 1.6rem;
    color: #333;
    margin-bottom: 15px;
}

.cooldown-timer {
    font-size: 1.2rem;
    color: red;
    margin-bottom: 10px;
}

/* The columns container (holding matches & likes) */
.info-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 20px; /* Raise columns a bit */
    gap: 20px; /* horizontal gap on desktop */
    flex-grow: 0; /* Let these columns only take needed space */
}

/* Each column (your matches, # liked you) */
.column {
    width: 48%; /* Just to split them in half on desktop */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.matched-list,
.likes-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}

.matched-list li,
.likes-list li {
    padding: 8px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 5px;
    text-transform: lowercase;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    max-width: 100%;
}

.empty-state {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    flex-grow: 1;
}

.column h3 + .empty-state,
.column h3 + .matched-list,
.column h3 + .likes-list {
    margin-top: 10px;
}

.column p {
    margin-top: 10px;
}

/* Confirmation popup (centered overlay) */
.confirmation-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 2px solid #800000;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.popup-buttons {
    margin-top: 15px;
}

.popup-buttons .like-button {
    margin: 0 10px;
}

/* "how does it work?" link near the bottom */
.how-does-it-work-container {
    /* Let it push to bottom if there's extra space */
    margin-top: auto;
    margin-bottom: 20px; 
    pointer-events: none; /* The span inside is clickable */
    display: flex;
    justify-content: center;
    align-items: center;
}

.how-does-it-work-container span {
    pointer-events: auto; /* Make sure the link is clickable */
    cursor: pointer;
    font-size: 0.9rem;
    white-space: nowrap;
    line-height: 1rem;
    text-decoration: underline;
    text-transform: lowercase;
}

/* ----------------------------------- */
/*            MOBILE LAYOUT           */
/* ----------------------------------- */
@media (max-width: 600px) {

    /* Slightly raise the input/button */
    .like-form {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 8px;
    }

    .like-input,
    .like-button {
        width: 100%;
        max-width: 300px;
    }

    /* Stack columns vertically */
    .info-columns {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
    }

    /* Lower "your matches" and "# of people who liked you" */
    .info-columns > .column:nth-child(1) {
        margin-top: 20px;
        order: 1;
    }
    .info-columns > .column:nth-child(2) {
        margin-top: 30px;
        order: 2;
    }

    .column {
        width: 100%;
        margin-bottom: 10px;
    }

    .form-title {
        font-size: 2rem;
        margin-bottom: 5px; 
    }
}
